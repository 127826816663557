.input-field-with-icon {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 0.5rem;
  background-color: #f0f0f0; /* Set the background color for the input field */
}

.input-field-with-icon .icon {
  margin-right: 0.5rem;
  color: #888;
}

.input-field-with-icon input {
  flex: 1;
  border: none;
  outline: none;
  padding: 0.5rem;
  font-size: 1rem;
  background-color: #f0f0f0; /* Ensure the input field background color matches */
  color: #333; /* Ensure input text color matches the placeholder color */
}

/* Placeholder styles */
.input-field-with-icon input::placeholder {
  color: #777; /* Change this color to match your desired placeholder color */
  opacity: 1; /* Override default opacity */
}

.input-field-with-icon input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #777;
}

.input-field-with-icon input::-ms-input-placeholder { /* Microsoft Edge */
  color: #777;
}