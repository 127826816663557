.login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.login-form {
  max-width: 400px;
  width: 100%;
  margin: 2rem;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
}

.login-form h1 {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: #333;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #555;
}

.login-form p {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #777;
}

.login-form .input-field-with-icon {
  margin-bottom: 1rem;
}

.login-form .button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 15px;
}

.login-form .button:hover {
  background-color: #0056b3;
}

/* Placeholder styles */
::placeholder {
  color: #777; /* Change this color to match your desired placeholder color */
  opacity: 1; /* Override default opacity */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #777;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #777;
}