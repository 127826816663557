.button {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 15px;
  }
  
  .button:hover {
    background-color: #0056b3;
  }